import * as React from 'react';

import {Admin, CustomRoutes, Resource} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {Route} from 'react-router';

import authProvider from './authProvider';
import {Layout, Login} from './layout';
import {Dashboard} from './dashboard';
import englishMessages from './i18n/en';
import {lightTheme} from './layout/themes';
import Configuration from './configuration/Configuration';
import Segments from './segments/Segments';

import PostgrestProvider from '@promitheus/ra-data-postgrest';

import Epoch from './entity/epoch'
import Block from './entity/block'
import Account from './entity/account'
import Tenant from "./entity/tenant";
import Message from "./entity/message";
import StorageDeal from "./entity/storage-deals";
import DealProposal from "./entity/deal-proposals";
import Sector from "./entity/sector"
import MinerPartition from "./entity/sector/miner_partition"
import SpLedgerEntry from "./entity/sp-ledger-entry";


const postgrest=PostgrestProvider('https://filbooks.app/api')
//const postgrest=PostgrestProvider('https://ledg.app/mainnet/api')

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }
    // Always fallback on english
    return englishMessages;
}, 'en');

// const dataProvFactory=dataProviderFactory(
//     process.env.REACT_APP_DATA_PROVIDER || ''
// )

// const combined=combineDataProviders((resource:string)=>{
//     //console.log({resource})
//     return postgrest;
//     // switch (resource){
//     //     case 'Sector':
//     //     case 'Account':
//     //     case 'Block':
//     //     case 'Epoch':
//     //     case 'Tenant':
//     //     case 'Message':
//     //         return postgrest;
//     //         break
//     //     default:
//     //         return dataProvFactory
//     // }
// })

const App = () => {

    return (
        <Admin
            title="FilBooks DEV"
            dataProvider={postgrest}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            theme={lightTheme}
        >
            <CustomRoutes>
                <Route path="/configuration" element={<Configuration />} />
                <Route path="/segments" element={<Segments />} />
            </CustomRoutes>
            {/*<Resource name="customers" {...visitors} />*/}
            {/*<Resource*/}
            {/*    name="commands"*/}
            {/*    {...orders}*/}
            {/*    options={{ label: 'Orders' }}*/}
            {/*/>*/}
            {/*<Resource name="invoices" {...invoices} />*/}
            {/*<Resource name="products" {...products} />*/}
            <Resource name="Epoch" {...Epoch} />
            <Resource name="StorageDeal" {...StorageDeal} />
            <Resource name="DealProposal" {...DealProposal} />
            <Resource name="MinerPartition" {...MinerPartition}/>
            <Resource name="Sector" {...Sector} />
            <Resource name="Block" {...Block}  />
            <Resource name="SpLedgerEntry" {...SpLedgerEntry}/>
            <Resource {...Message}  />
            <Resource name="Account" {...Account} />
            {/*<Resource name="categories" {...categories} />*/}
            {/*<Resource name="reviews" {...reviews} />*/}
            <Resource name="Tenant" {...Tenant}/>
            <Resource name={"State"}/>
        </Admin>
    );
};

export default App;
