import React from 'react';
import {RecordContextProvider, useGetOne} from 'react-admin';
import {Grid} from '@mui/material';
import DashboardCard from './DashboardCard';

import {BlockchainState} from '../types';

// interface OrderStats {
//     revenue: number;
//     nbNewOrders: number;
//     pendingOrders: Order[];
// }
//
// interface State {
//     nbNewOrders?: number;
//     pendingOrders?: Order[];
//     recentOrders?: Order[];
//     revenue?: string;
// }
//
// const styles = {
//     flex: { display: 'flex' },
//     flexColumn: { display: 'flex', flexDirection: 'column' },
//     leftCol: { flex: 1, marginRight: '0.5em' },
//     rightCol: { flex: 1, marginLeft: '0.5em' },
//     singleCol: { marginTop: '1em', marginBottom: '1em' },
// };
//
// const Spacer = () => <span style={{ width: '1em' }} />;
// const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard = () => {


    const {data,isLoading,error} = useGetOne<BlockchainState>('State', {id:0})
    if (isLoading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    return(
        <RecordContextProvider value={data}>

            <Grid container spacing={3}>
                {/*<Card>Card1</Card>*/}
                {/*<Card>Card2</Card>*/}
                {/*<Card>Card3</Card>*/}
                {/*<Card>Card4</Card>*/}
                {/*<Card>Card5</Card>*/}
                {/*<Card>Card6</Card>*/}
                {/*<Card>Card7</Card>*/}
                {/*<Card>Card8</Card>*/}
                <DashboardCard source="top"/>

                <DashboardCard source="base_fee"/>

                <DashboardCard source="total_supply"/>
                <DashboardCard source="active_miners"/>
                <DashboardCard source="avg_block_interval"/>
                <DashboardCard source="avg_blocks_per_tipset"/>
                <DashboardCard source="avg_reward_24H"/>
                <DashboardCard source="block_reward"/>
                <DashboardCard source="burnt_fil"/>
                {/*<DashboardCard source="avg_reward_24H"/>*/}
                {/*<DashboardCard source="block_reward"/>*/}
                {/*<DashboardCard source="burnt_fil"/>*/}
                {/*<DashboardCard source="circulating_rate"/>*/}
                {/*<DashboardCard source="circulating_supply"/>*/}
                {/*<DashboardCard source="cost_of_sealing_sector"/>*/}
                {/*<DashboardCard source="fil_production_24"/>*/}
                {/*<DashboardCard source="initial_pledge_total"/>*/}
                {/*<DashboardCard source="latest_block"/>*/}
                {/*<DashboardCard source="messages_24h"/>*/}
                {/*<DashboardCard source="qap"/>*/}
                {/*<DashboardCard source="rbp"/>*/}
                {/*<DashboardCard source="sector32_initial_pledge"/>*/}
                {/*<DashboardCard source="total_accounts"/>*/}

            </Grid>
            <h2>DEV</h2>
        </RecordContextProvider>
    )
};

export default Dashboard;
